<template>
  <h1>Leider ist etwas schiefgelaufen</h1>
  <p>
    Aufgrund eines Fehlers mussten wir dich leider ausloggen. Probiere es
    einfach nochmal :)
  </p>
</template>

<script>
import { handleAuth } from "@/mixins/handleAuth";
export default {
  mixins: [handleAuth],
  mounted() {
    this.logout();
  },
};
</script>
